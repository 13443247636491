<template>
<!-- 直播大厅 -->
<div class='live_main'>
  <header-base></header-base>

  <div class="cont_title">
    <div class="title_left">
      <ul>
        <li v-for="(i,k) in leftLiveList" :key="k"
        :class="isact==k?'isact':''"
        @click="isact=k,toLive(i)">
          <img :src="i.coverUrl" alt="">
          <el-icon><video-camera /></el-icon>
        </li>
      </ul>
    </div>

    <div class="title_right">
      <!-- <el-button>进入直播间</el-button> -->
      <iframe
        :src="path"
        width="100%"
        height="100%"
        ref="iframe"
        frameborder="0"
      ></iframe>
    </div>

    <menu-list></menu-list>
  </div>

  <div class="cont_body">
    <div class="body_title">
      <h4>{{$t('m.live.Wonderful_live_streaming')}}</h4>
      <p class="right">
        <span>{{liveUser}}{{$t('m.live.hosts_are_live_streaming')}}</span>
        <strong>{{$t('m.live.Much_more')}}</strong>
      </p>
    </div>

    <div class="body_cont">
      <ul>
        <li v-for="(i,k) in liveList" :key="k">
          <div class="lidiv" @click="toLive(i)">
            <span class="numcont">{{i.onlineCount}}人在线</span>
            <el-icon><video-camera /></el-icon>
            <img :src="i.coverUrl" alt="">
          </div>
        </li>
        <p class="cont_p" v-if="liveList.length == 0">... {{$t('m.live.No_live_streaming_information_available')}} ...</p>
      </ul>
    </div>
  </div>
  <div id="foot">
    <Footer/>
  </div>
</div>
</template>

<script>
import { ref } from 'vue-demi'
import HeaderBase from '../../components/HeaderBase.vue'
import Footer from '../index/Footer.vue'
import MenuList from './menuList.vue'
import http from '../../api/http'
import { ElLoading } from 'element-plus'
import { getStore } from '../../tools/storage'

// 查询直播大厅内容
const listLiveRooms = (data) => {
  return http.get('/mall-portal/aliyunLive/listLiveRooms', data)
}
// 获取直播链接
const getStandardRoomJumpUrl = (data) => {
  return http.post('/mall-portal/aliyunLive/getStandardRoomJumpUrl', data)
}
export default {
  name: 'LiveIndex',
  data () {
    return {
      liveUser: ref(0), // 直播数
      leftLiveList: ref([]), // 大厅直播左侧数据
      isact: ref(0), // 选中的数据
      liveList: ref([]), // 大厅直播数据

      bizId: ref(''),
      path: ref('') // 直播间链接
    }
  },
  components: { HeaderBase, Footer, MenuList },
  methods: {
    async initData (size) { // 初始化直播大厅数据
      const loading = ElLoading.service({
        lock: true,
        text: '请稍等...'
      })
      const data = {
        status: '1', // 0已创建未开播 1直播中 2直播结束
        pageNumber: 1,
        pageSize: size
      }
      const res = await listLiveRooms(data)

      if (size === 4) {
        if (res.status === 200) {
          this.leftLiveList = res.data.body.result.liveList
          if (this.leftLiveList.length > 0) {
            this.bizId = this.leftLiveList[0].liveId
            this.rightLive()
          } else {
            this.leftLiveList = [{
              coverUrl: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/admin/live/nodata/%E7%BB%84%20109%20%E6%8B%B7%E8%B4%9D%203.png'
            }, {
              coverUrl: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/admin/live/nodata/%E5%9B%BE%E5%B1%82%207.png'
            }, {
              coverUrl: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/admin/live/nodata/%E5%9B%BE%E5%B1%82%206.png'
            }, {
              coverUrl: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/admin/live/nodata/%E5%9B%BE%E5%B1%82%205.png'
            }]
          }
          loading.close()
        }
      } else {
        console.log(res)
        if (res.status === 200) {
          this.liveList = res.data.body.result.liveList
          this.liveUser = this.liveList.length
          loading.close()
        }
      }
    },
    async toLive (item) {
      console.log(item.anchorId)
      if (item.anchorId !== undefined) {
        this.$router.push({
          path: '/live/detail',
          query: {
            anchorId: item.anchorId,
            bizId: item.liveId
          }
        })
      }
      // const data = {
      //   bizId: item.liveId
      // }
      // const res = await getStandardRoomJumpUrl(data)
      // console.log(res)
      // if (res.data.code === 200) {
      //   window.open(res.data.data.body.result.standardRoomJumpUrl, '_blank')
      // }
    },
    async rightLive () {
      const userId = getStore('usermessage') ? JSON.parse(getStore('usermessage')) : null
      console.log(userId)
      const data = {
        bizId: this.bizId,
        businessOrUser: 1,
        userId: userId ? userId.id : null
      }
      const leftlive = await getStandardRoomJumpUrl(data)
      console.log(leftlive)
      if (leftlive.status === 200) {
        this.path = leftlive.data.body.result.standardRoomJumpUrl
        // window.open(res.data.data.body.result.standardRoomJumpUrl, '_blank')
      }
    }
  },
  mounted () {
    this.initData(8)
    this.initData(4)
  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>

<style lang='scss' scoped>
//@import ''; 引入公共css类
.live_main{
  background: #f4f0ed;
  font-family: Microsoft YaHei;
  font-weight: 400;
  .cont_title{
    width: 1200px;
    background: #ffffff;
    padding: 5px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    .title_left{
      flex: 1;
      padding: 10px 0;
      ul{
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        li{
          // flex: 1;
          cursor: pointer;
          position: relative;
          border: 5px solid transparent;
          border-radius: 4px;
          background: #5c5c5c1a;
          img{
            height: 100%;
            // height: 150px;
            width: 260px;
          }
          .el-icon{
            font-size: 40px;
            color: #ffffff;
            position: absolute;
            top: 38%;
            left: 45%;
          }
        }
        .isact{
          border-color: #ffa419;
        }
      }
    }
    .title_right{
      margin: 10px;
      // display: flex;
      // flex: 1;
      width: 1000px;
      height: 710px;
      align-items: center;
      justify-content: center;
      background: #000000;
    }
  }
  .cont_body{
    width: 1200px;
    margin: 0 auto;
    background: #ffffff;
    // padding: 5px;
    box-sizing: border-box;
    .body_title{
      height: 60px;
      padding: 0 20px;
      background: #DCD8D8;
      border: 1px solid #595959;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4{
        font-size: 29px;
        font-weight: bold;
        color: #000000;
      }
      .right{
        span{
          font-size: 21px;
          color: #F60000;
        }
        strong{
          margin-left: 30px;
          font-size: 29px;
          color: #333333;
          cursor: pointer;
        }
      }
    }
    .body_cont{
      padding: 40px 0 0;
      ul{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // justify-content: space-between;
        li{
          // flex: 1;
          width: 25%;
          margin-bottom: 40px;
          .lidiv{
            cursor: pointer;
            margin: 0 auto;
            width: 260px;
            height: 260px;
            position: relative;
            .numcont{
              width: 126px;
              height: 20px;
              font-size: 12px;
              color: #ffffff;
              text-align: center;
              line-height: 20px;
              background: #EB6100;
              border-radius: 0px 0px 13px 0px;
              position: absolute;
            }
            .el-icon{
              position: absolute;
              color: #ffffff;
              font-size: 50px;
              top: 40%;
              left: 45%;
            }
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
        .cont_p{
          width: 100%;
          text-align: center;
          color: #FF0000;
          font-size: 30px;
          margin-bottom: 30px;
        }
      }
    }
  }
  #foot{
    background: #280E01;
  }
}
</style>
